#root {
   height: 100vh;
}

@font-face {
   font-family: fontRegular;
   src: url('./assets/fonts/SFProText-Regular.ttf');
}

@font-face {
   font-family: fontMedium;
   src: url('./assets/fonts/SFProText-Medium.ttf');
}

@font-face {
   font-family: fontBold;
   src: url('./assets/fonts/SFProText-Bold.ttf');
}

@font-face {
   font-family: InferFont;
   src: url('./assets/fonts/Inter-VariableFont_slnt,wght.ttf');
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
   font-family: 'InferFont';
   scrollbar-width: none;
   scrollbar-color: #a6a6a6 #ffffff;
}

input[type='number'] {
   -moz-appearance: textfield;
   /* Firefox */
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
   width: 11px;
}

*::-webkit-scrollbar-track {
   background: #ffffff;
}

*::-webkit-scrollbar-thumb {
   background-color: #a6a6a6;
   border-radius: 12px;
   border: 3px solid #ffffff;
}

input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }

.txtLink {
   width: 100%;
   overflow: hidden;
   text-overflow: ellipsis;
   line-height: 25px;
   -webkit-line-clamp: 3;
   height: 75px;
   display: -webkit-box;
   -webkit-box-orient: vertical;
}

.txtMax2Line {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
   font-size: small;
}
.txtMax3Line {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
   -webkit-box-orient: vertical;
   font-size: small;
}

.activeTxt {
   color: #0b86da;
   text-decoration: underline;
}

.txtMax2LineHover {
   /* overflow: hidden; */
   /* text-overflow: ellipsis; */
   /* display: -webkit-box; */
   /* -webkit-line-clamp: 2; */
   /* number of lines to show */
   /* -webkit-box-orient: vertical; */
   font-size: small;
}

.txtMax2LineTitle {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
   font-size: smaller;
   font-weight: bold;
}

.site-logo {
   margin: 0 auto;
}

.img-logo {
   width: 100px;
   height: auto;
}

.text-right {
   text-align: right;
}

.select-status {
   width: 110px;
   text-align: left;
}

.select-status-voucher {
   width: 120px;
   text-align: left;
}

.select-key-date {
   width: 150px;
   text-align: left;
}

.txt-des-option {
   font-size: 14px;
   text-align: center;
   margin-right: 5px;
}

.m-container {
   width: 100%;
   padding-left: 16px;
   padding-right: 16px;
}
@media (min-width: 992px) {
   .m-container {
      width: 992px;
   }
}

@media (min-width: 1100px) {
   .m-container {
      width: 1100px;
   }
}

@media (min-width: 1300px) {
   .m-container {
      width: 1300px;
   }
}

@media (min-width: 1600px) {
   .m-container {
      width: 1440px;
   }
}

.row-container-head {
   margin-top: 20px;
   margin-bottom: 10px;
}

.btn-add {
   margin-bottom: 0;
   display: flex;
}

.image-document .ant-upload.ant-upload-select-picture-card {
   width: 300px;
   height: 170px;
}

.site-layout .site-layout-background {
   background: #fff;
}

.textRight {
   text-align: right;
}

.containerPagination {
   margin: 16px 0;
   text-align: center;
}

.loading {
   position: fixed;
   left: 0px;
   top: 0px;
   width: 100%;
   height: 100%;
   z-index: 1000;
   background-color: #00000040;
}

.loading .spinner {
   position: fixed;
   width: 100%;
   height: 100%;
   z-index: 1010;
   top: 50%;
}

.ant-input-number {
   width: 100%;
}

.head-title {
   font-weight: 500;
}

.head-order {
   margin-top: 20px;
}

.color-green {
   color: #52c41a;
}

.ant-form-item-label label {
   font-weight: 500;
}

.form-create .ant-upload img {
   max-width: 100%;
   max-height: 100%;
}

.icon-login {
   width: 190px;
   height: 190px;
}

.logo-login {
   width: 300px;
   height: 76px;
}

.container-login {
   margin: 0 auto;
   max-width: 450px;
   width: 100%;
   background: #ffffff;
   box-shadow: 0px 3px 10px rgba(142, 142, 142, 0.2);
}

.container-icon-login {
   position: relative;
}

.div-icon-login {
   position: absolute;
   left: 28%;
   top: -100px;
}

.div-logo-login {
   text-align: center;
   margin-top: 123px;
   margin-bottom: 43px;
}

.container-form {
   padding-left: 67px;
   padding-right: 67px;
}

.btn-add .icon-in-button {
   padding-top: 2px;
   margin-right: 6px;
}

.icon-antd-button {
   margin-right: 6px;
}

.margin-left-15 {
   margin-left: 15px;
}

.center-img-content {
   width: 40px;
   height: 40px;
   display: flex;
   align-items: center;
}

.center-img-content2 {
   width: 100px;
   height: 50;
   display: flex;
   align-items: center;
}

.img-responsive {
   max-width: 100%;
   max-height: 100%;
}

.gx-max-width {
   max-width: 100%;
}

.gx-no-wrap {
   flex-wrap: nowrap;
}

.text-oneline {
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
   overflow: hidden;
   line-break: anywhere;
}

.center-img-content,
.gx-avatar {
   width: 40px;
   height: 40px;
}

.drawer-container .ant-drawer-body {
   background-color: #17413F;
   /* background-color: #063366; */
}

.div-filter {
   margin-left: 20px;
}

.form-no-label label {
   display: none;
}

.total-items {
   margin-bottom: 16px;
   font-weight: 500;
}

.image-avatar {
   min-width: 40px;
   margin-right: 0.5rem;
}

.img-qr-code {
   width: 50px;
   height: 50px;
}

.m-full-width {
   width: 100%;
}

.gx-logo {
   width: 175px;
   height: 32px;
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.m-horizon-nav__menu.ant-menu-horizontal > .ant-menu-item {
   margin: 0 24px;
}

.popoverNoPadding .ant-popover-inner-content {
   padding: 0 !important;
}

.popoverNoPadding .gx-sub-popover {
   margin: 0 !important;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 991px) {
   .gx-logo {
      width: 125px;
      height: 24px;
      margin-left: 16px;
   }

   .menu-container {
      margin-top: 20px;
   }

   .header-mobile-container {
      height: 50px;
      padding: 5px 5px;
      box-shadow: 0 0 4px 4px rgb(0 0 0 / 8%);
      color: #fff;
      background-color: #272944;
      display: flex;
      justify-content: space-between;
   }
   .menu-container li {
      padding-left: 0px !important;
   }

   .header-mobile-container {
      height: 50px;
      padding: 5px 5px;
      box-shadow: 0 0 4px 4px rgb(0 0 0 / 8%);
      color: #fff;
      background-color: #063366;
      display: flex;
      justify-content: space-between;
   }

   .image-avatar {
      margin-right: 0rem;
   }
}

.filter-overlay__wrapper .ant-popover-inner-content {
   padding: unset;
}

.bank-name__select .select__control {
   border-radius: 6px;
   cursor: pointer;
}

.bank-name__select .select__control .select__value-container {
   padding-left: 27px;
}
.ant-form-item-has-error .bank-name__select .select__control {
   border-color: red;
}

.modal__padding-16 .ant-modal-body {
   padding: 16px;
}

.custom-confirm__modal .ant-modal-confirm-body .ant-modal-confirm-content {
   margin-top: unset;
}

.custom-confirm__modal .ant-modal-confirm-btns {
   display: none;
}

.custom-btn__modal .ant-modal-confirm-btns button.ant-btn.ant-btn-primary {
   font-size: 14px;
   line-height: 22px;
   padding: 8px 16px;
   border-radius: 8px;
   color: white;
   border: none;
   border-radius: 4px;
   -webkit-align-items: center;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   cursor: pointer;
   font-weight: 400;
   white-space: nowrap;
   background-color: #ffad63 !important;
   &:hover {
      opacity: 0.9;
   }
}

@media screen and (max-width: 480px) {
}
/*Iphone(480 x 640)*/
@media screen and (max-width: 320px) {
}


.color-label label.ant-form-item-no-colon {
   color: #038fde;
   font-weight: bold;
   
}
